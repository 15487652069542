$equalizer-black: #000;
$equalizer-grey: #999;

* {
  box-sizing: border-box;
  font-family: sans-serif;
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
span,
label,
input,
a,
button,
select,
textarea {
  color: $equalizer-black;
  display: inline-block;
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  margin: 0;

  &:focus {
    outline: none;
  }
}

select {
  -moz-appearance: none;
  -webkit-appearance: none;
}

table {
  border-collapse: collapse;
  table-layout: fixed;
}

input,
select,
textarea,
button {
  border: 0;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

button {
  background: transparent;
  cursor: pointer;
}

button::-moz-focus-inner {
  border: 0;
}

a {
  cursor: pointer;
  text-decoration: none;

  &:focus,
  &:active,
  &:hover {
    color: $equalizer-black;
    text-decoration: none;
  }
}

ul,
li {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

::placeholder {
  color: $equalizer-grey;
  opacity: 1;
}
