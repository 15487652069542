// @font-face {
//   font-family: 'optima_0';
//   src: url('../assets/optima_0.ttf') format('truetype');
// }
//
// @font-face {
//   font-family: 'optima_1';
//   font-weight: bold;
//   src: url('../assets/optima_1.ttf') format('truetype');
// }

@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,700');

*,
p,
h3,
h4 {
  //font-family: 'optima_0';
  font-family: 'Open Sans', sans-serif;
  color: $black;
  font-size: 16px;
  line-height: 25px;

  @media (max-width: 768px) {
    font-size: 12px;
  }
}

.hide {
  display: none;
}

.es-btn,
.en-btn {
  line-height: 70px;

  @media (max-width: 768px) {
    padding: 10px 15px;
    line-height: 60px;
  }
}

.es-btn-wrapper {
  margin-right: 10px;
}

.active-btn {
  color: $red;
}

.navbar-default .navbar-nav > li > a {
  line-height: 40px;
}

.brand {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 1;
  font-size: 40px;
  text-transform: uppercase;
  line-height: 50px;

  @media (max-width: 768px) {
    font-size: 30px;
    line-height: 40px;
    width: 100%;
    padding: 0 10px;
  }
}

.footer {
  background-color: $red;
  color: $white;
  padding: 20px;

  p {
    font-size: 12px;
    text-align: center;

    @media (max-width: 768px) {
      font-size: 10px;
    }
  }
}

html {
  height: 100%;
}

body {
  height: 100%;
  position: relative;
}

section {
  background-color: $white;
  padding: 75px 50px;
  padding-bottom: 60px;
  text-align: center;

  @media (max-width: 768px) {
    padding: 20px;
    padding-bottom: 40px;
  }
}

.logo-nav {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  height: 40px;

  a {
    height: 40px;
  }

  img {
    max-height: 100%;
  }
}

.section-main {
  object-fit: cover;
  height: 100%;
  padding: 0;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    height: 100%;
    width: 100%;
    background: $black;
    opacity: 0.6;
  }

  .img-background {
    height: 100%;
    width: 100%;
    filter: grayscale(100%);
  }

  .logo {
    bottom: 70px;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    z-index: 1;

    .firm {
      font-size: 16px;
    }
  }
}

.title-1 {
  text-transform: uppercase;
  color: $black;
  margin-bottom: 20px;
  font-size: 20px;

  @media (max-width: 768px) {
    font-size: 16px;
  }
}

.title-3 {
  color: $black;
  margin-bottom: 20px;
  font-size: 16px;
  line-height: 25px;

  @media (max-width: 768px) {
    font-size: 14px;
    line-height: 20px;
  }
}

.title-2 {
  font-size: 15px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 10px;
  color: $red;

  @media (max-width: 768px) {
    font-size: 12px;
  }
}

.text-base {
  &.grey {
    color: $grey;
  }

  &.white {
    color: $white;
  }
}

.areas-wrapper {
  column-count: 3;
  text-align: left;

  li {
    padding-left: 10px;
  }

  li:before {
    content: "-";
    margin-right: 5px;

    @media (max-width: 768px) {
      content: '';
    }
  }

  @media (max-width: 768px) {
    column-count: 1;
    text-align: center;
  }
}

// Layout

.max-wrapper {
  max-width: 1000px;
  margin: 0 auto;
}

.row.profile {
  max-width: 620px;
  text-align: left;

  .title-2 {
    font-size: 14px;
    line-height: 14px;
  }

  p {
    font-size: 13px;
    line-height: 16px;
  }

  &:not(:last-child) {
    margin-bottom: 55px;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    margin: 0;
    text-align: center;

    .column.column-left {
      align-items: center;
    }
  }
}

.photo-wrapper {
  width: 100px;
  height: 100px;
  margin-right: 10px;
  border-radius: 5px;

  img {
    object-fit: cover;
    border-radius: 5px;
    width: 100px;
    height: 100px;
  }

  @media (max-width: 768px) {
    margin-bottom: 30px;
  }
}

.contact-item:not(:last-child) {
  &:after {
    content: '|';
    margin: 0 10px;

    @media (max-width: 768px) {
      content: ''
    }
  }
}

.column {
  display: flex;
  flex-direction: column;
}

.column-center {
  align-items: center;
}

.column-left {
  align-items: flex-start;
}

.row {
  align-items: center;
  display: flex;
  flex-direction: row;
  margin: 0;
}

.row-top {
  align-items: flex-start;
}

.row-center {
  justify-content: center;
}

.row-left {
  justify-content: flex-start;
}

.row-right {
  justify-content: flex-end;
}

.row-space-between {
  justify-content: space-between;
}

.item-center {
  align-self: center;
  text-align: center;
}

.item-left {
  align-self: flex-start;
  text-align: left;
}

.item-right {
  align-self: flex-end;
  text-align: right;
}

.flex-grow-1 {
  flex-grow: 1;
}

@for $i from 1 through 3 {
  .item-#{$i} {
    flex: $i;
    min-width: 0;
  }
}

// Margins

.m-bottom-1 {
  margin-bottom: 10px;
}

.m-bottom-2 {
  margin-bottom: 20px;
}

.m-bottom-3 {
  margin-bottom: 30px;
}

.m-bottom-5 {
  margin-bottom: 50px;
}

.m-bottom-7 {
  margin-bottom: 70px;
}

.m-right-1 {
  margin-right: 10px;
}

.m-right-2 {
  margin-right: 20px;
}

.m-right-3 {
  margin-right: 30px;
}

.m-right-5 {
  margin-right: 50px;
}

.m-left-1 {
  margin-left: 10px;
}

.margin-10 {
  margin: 10px;
}

// Scroll indicator

.mouse {
  bottom: 0;
  margin-top: 50px;
  width: 100px;
}

.mouse-icon {
  border: 2px solid $white;
  border-radius: 15px;
  cursor: pointer;
  height: 45px;
  margin: auto;
  position: relative;
  text-align: center;
  width: 25px;
}

.mouse-wheel {
  animation: 1.6s ease infinite wheel-up-down;
  background-color: $white;
  border-radius: 50%;
  display: block;
  height: 6px;
  margin: 2px auto 0;
  width: 3px;
}

.bar {
  height: 3px;
  width: 90px;
  background-color: #AC1F31;
}

@keyframes wheel-up-down {
  0% {
    margin-top: 2px;
    opacity: 0;
  }

  30% {
    opacity: 1;
  }

  100% {
    margin-top: 20px;
    opacity: 0;
  }
}

/************************** GOOLE SECTION **************************/

.google-map {
	padding: 0;
	min-height: inherit
}

#google-container {
	padding: 0;
	position: relative;
	width: 100%;
	height: 350px;
}

#cd-google-map {
  position: relative;
  min-height: inherit;
}

.google-map {
	padding-top: 100px ;
}

#cd-zoom-in, #cd-zoom-out {
  height: 32px;
  width: 32px;
  cursor: pointer;
  margin-left: 10px;
  background-color: $red;
  background-repeat: no-repeat;
  background-size: 32px 64px;
  background-image: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/148866/cd-icon-controller.svg");
}
.no-touch #cd-zoom-in:hover, .no-touch #cd-zoom-out:hover {
  background-color: $red;
}

#cd-zoom-in {
  background-position: 50% 0;
  margin-top: 10px;
  margin-bottom: 1px;
}

@media screen and (min-width:768px){
    .navbar-brand-centered {
        position: absolute;
        left: 50%;
        display: block;
        width: 160px;
        text-align: center;
        background-color: #eee;
    }
    .navbar>.container .navbar-brand-centered,
    .navbar>.container-fluid .navbar-brand-centered {
        margin-left: -80px;
    }
}

.navbar-default {
  border-radius: 0;
  background: $white !important;
  border: 0;
  margin-bottom: 0 !important;
  height: 70px;
  position: fixed;
    z-index: 5;
    width: 100%;
}

.navbar-collapse {
  background: white;
    border: 0;
}

.navbar-default .navbar-toggle:hover {
  background-color: transparent;
}

.container {
  background: white !important;
    border: 0;
    height: 100%;
}

.navbar-toggle {
  margin-top: 18px;
}

.navbar-brand {
  padding: 10px;
margin: 0;
height: 70px;
background: none;

  .anchor {
    max-height: 100%;
    height: 70px;
  }

  img {
    max-height: 100%;
height: 100%;
  }
}
